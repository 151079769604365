import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import './style.scss'
import Spinner from '../Spinner'

const SmartSearch = ({ pagination, posts, request, isLoading, strings }) => {
  if (isLoading && request && !posts.length) {
    return <Spinner />
  }
  const [page, setPage] = useState(0)

  useEffect(() => {
    setPage(0)
  }, [request])

  const postsCount = posts.length
  const postPerPage = 10
  const currentPage = page + 1
  const maxPages = Math.ceil(postsCount / postPerPage)
  const pagePosts = [
    ...posts.slice(page * postPerPage, page * postPerPage + postPerPage),
  ]
  if (request && postsCount) {
    return (
      <div className="dhsv_search_result">
        <p className="search-result__heading">
          <span>{`${strings.result} ${page * 10 + 1} ${strings.to} ${
            page * 10 + pagePosts.length
          } ${strings.for} `}</span>
          <span className="search-result__request">{request}</span>
        </p>
        <div className="search-result">
          {pagePosts.map((item, i) => (
            <div key={i} className="search-result__item">
              <a href={item.permalink} className="item__link">
                <span className="item__title">{item.title}</span>
                {item.posttype ? (
                  <span className="item__posttype">{item.posttype}</span>
                ) : (
                  ''
                )}
                <i className="la la-arrow-right" />
              </a>
              {item.excerpt && pagination ? (
                <div
                  className="item__excerpt"
                  dangerouslySetInnerHTML={{ __html: item.excerpt }}
                />
              ) : (
                ''
              )}
            </div>
          ))}
        </div>
        {postsCount > postPerPage && pagination ? (
          <div className="pagination">
            {currentPage !== 1 ? (
              <button
                className="page-btn"
                onClick={() => setPage(page - 1)}
              >{`< ${strings.prev_page}`}</button>
            ) : (
              ''
            )}
            {currentPage !== maxPages ? (
              <button className="page-btn" onClick={() => setPage(page + 1)}>
                {`${strings.next_page} >`}
              </button>
            ) : (
              ''
            )}
          </div>
        ) : null}
      </div>
    )
  } else if (request) {
    return (
      <div className="search-result__no-found">
        <span>{strings.no_result}</span>
      </div>
    )
  } else {
    return null
  }
}

SmartSearch.propTypes = {
  posts: PropTypes.array,
  isMobile: PropTypes.bool,
  pagination: PropTypes.bool,
  isLoading: PropTypes.bool,
  request: PropTypes.string,
  strings: PropTypes.object,
}

export default SmartSearch
