import { connect } from 'react-redux'
import Component from './component'
import PropTypes from 'prop-types'
import { sIsMobile } from '../../redux/modules/browser'

const WithData = connect(state => ({
  isMobile: sIsMobile(state),
  request: state.search.request,
  posts: state.search.posts,
  isLoading: state.search.isLoading,
}))(Component)

WithData.propTypes = {
  isMobile: PropTypes.bool,
}

export default WithData
